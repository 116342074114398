<template>
  <div class="m" :style="{backgroundImage: 'url(' + imageUrl + ')'}">
    <div style="position: absolute; left: 60%; top: 50%; transform: translate(-136%, -58%)">
      <h2 style="font-size: 48px; margin-bottom: 30px; color: #fff;text-align:left;">{{ title }}</h2>
      <div style="margin-bottom: 15px; height: 2px; width: 108px; background-color: #fff;margin-bottom: 30px;">&nbsp;
      </div>
      <p style="font-size:24px; width: 580px; color: #fff" v-html="contentX" @click="toYemian(contentX)"></p>
    </div>
    <div class="banner-img" style="display: none;">
      <div style="display: flex;">
        <div class="box-img" style="margin-right: 40px;"><img src="@/assets/img1.png" alt=""
            style="width: 250px;height: 116.67px;"></div>
        <div class="box-img"><img src="@/assets/img2.png" alt="" style="width: 250px;height: 116.67px;"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imageUrl: String,
    title: String,
    contentX: String,
  },
  setup() {
    return {
    };
  },
  methods: {
    toYemian(item) {
      console.log(item);
      if (item.indexOf("button") !== -1) {
        window.open('https://fw.hhzb.com.cn/', '_blank');
      }
    }
  }
};
</script>
<style>
@import url("./NewBanner.css");
</style>